<template lang="pug">
.forgot-password-dialog 
  v-dialog(
    max-width="500"
    persistent
    v-model="isOpen"
  )
    template( v-slot:activator="{ on, attrs }" )
      a( v-bind="attrs" v-on="on" ) Forgot your password?

    v-form( 
      ref="forgotPasswordForm" 
      v-model="valid" 
      @submit.prevent="resetPassword" 
    )
      v-card
        v-card-title.bg-gray-200.justify-between
          div Forgot Password
          v-spacer
          div
            v-btn(
              fab
              small
              depressed
              @click="isOpen = false"
            )
              v-icon mdi-close

        v-card-text.pa-8.py-10
          .text-lg.text-gray-800.mb-4 
            | Enter email to reset password

          v-text-field(
            outlined
            dense
            :rules="[rules.required, rules.email]"
            label="Email"
            v-model="email"
          )

        v-card-actions( right ).bg-gray-100
          v-btn(
            depressed
            @click="isOpen = false"
          ) Cancel 
          v-btn(
            depressed
            type="submit"
            :disabled="!valid"
            color="primary"
          ) Reset
  
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidations from '@/helpers/formValidations'

export default {
  name: 'ForgotPasswordDialog',

  methods: {
    async resetPassword() {
      const { AuthManagement } = this.$FeathersVuex.api
      const authManagement = new AuthManagement({
        action: "sendResetPwd",
        value: { email: this.email }
      })

      try {
        await authManagement.create();
      } catch (error) { console.log('Sent') }
      
      this.isOpen = false;
      this.$snackSuccess('Email with instructions has been sent')
    }
  },

  setup() {
    const isOpen = ref(false)
    const email = ref('')
    const { valid, rules } = formValidations()

    return {
      email,
      rules,
      isOpen,
      valid
    }
  }
}
</script>

<style>
</style>